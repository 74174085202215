<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template v-if="!buying">
      <v-card>
        <v-card-text>
          <v-layout row wrap mx-4 my-2>
            <v-flex xs4>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink>
              <v-dialog
                transition="dialog-top-transition"
                max-width="400"
                v-model="dialogNumber"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on"
                    >Add <v-icon class="ml-2">mdi-phone</v-icon>
                  </v-btn>
                </template>
                <template>
                  <v-card>
                    <v-toolbar color="primary" dark>Add Phone Number</v-toolbar>
                    <v-card-text>
                      <v-row class="mt-2">
                        <v-col cols="12">
                          <v-form v-model="formValid">
                            <v-text-field
                              label="Number"
                              v-model="phoneNumber"
                              :rules="[rules.required, rules.isPhone]"
                              outlined
                              prepend-inner-icon="mdi-numeric-positive-1"
                            ></v-text-field>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        class="primary"
                        :disabled="!formValid"
                        @click="addNumber"
                        >Confirm</v-btn
                      >
                      <v-btn text @click="close">Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
              <v-btn @click="repairNumber" class="ml-4" color="warning"
                >Repair <v-icon class="ml-2">mdi-phone-settings</v-icon>
              </v-btn>
              <v-btn @click="toShop(true)" class="ml-4" color="success"
                >Buy <v-icon class="ml-2">mdi-phone-plus</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <template>
            <v-data-table
              :search="search"
              dense
              :loading="loading"
              :headers="headersSettings"
              :items="phoneNumbers"
              item-key="uuid"
              class="elevation-1"
            >
              <template v-slot:[`item.employee`]="{ item }">
                {{ item.employee == null ? "-" : item.employee.fullname }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="toDelete(item)"
                        icon
                        small
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Number</span>
                  </v-tooltip>

                  <!--   <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="toEdit(item)"
                  icon
                  small
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Number</span>
            </v-tooltip> -->
                  <template v-if="item.employee == null">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="toAsign(item)"
                          icon
                          small
                          v-on="on"
                          v-bind="attrs"
                          color="success"
                        >
                          <v-icon small>mdi-account-switch</v-icon>
                        </v-btn>
                      </template>
                      <span>Assign Number</span>
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="toFree(item)"
                          icon
                          small
                          v-on="on"
                          v-bind="attrs"
                          color="warning"
                        >
                          <v-icon small>mdi-account-remove</v-icon>
                        </v-btn>
                      </template>
                      <span>Free Number</span>
                    </v-tooltip>
                  </template>
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title
              >Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" @click="close" text>Cancel</v-btn>
              <v-btn color="blue darken-1" @click="deletenumber" text>OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="freeDialog" max-width="500px">
          <v-card>
            <v-card-title
              >Are you sure you want to free this number?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" @click="close" text>Cancel</v-btn>
              <v-btn color="blue darken-1" @click="free" text>OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          transition="dialog-top-transition"
          max-width="400"
          v-model="assignDialog"
        >
          <template>
            <v-card>
              <v-toolbar color="primary" dark>Assign number</v-toolbar>
              <v-card-text>
                <v-row class="mt-2">
                  <v-col cols="12">
                    <v-form v-model="formValid">
                      <v-autocomplete
                        label="Employee"
                        :items="workers"
                        item-text="fullname"
                        item-value="uuid"
                        v-model="req.employeeUuid"
                        :rules="[rules.required]"
                        outlined
                      ></v-autocomplete>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  :disabled="!formValid"
                  text
                  @click="assign"
                  class="primary"
                  >Assign</v-btn
                >
                <v-btn text @click="close">Close</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-card-actions> </v-card-actions>
      </v-card>
    </template>
    <template v-else>
      <v-card>
        <v-card-text>
          <v-layout row wrap class="mx-4">
            <v-flex xs12> <strong>Twilio Numbers </strong> </v-flex>
          </v-layout>
          <v-layout row wrap mx-4 my-2>
            <v-flex xs4>
              <v-text-field
                v-model="codeArea"
                append-icon="mdi-magnify"
                label="Code Area"
                single-line
                hide-details
                maxlength="3"
                @keydown.enter="getFreeNumbers()"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink>
              <template v-if="itemBuy.count > 1">
                <v-btn small @click="toShop(true)" class="ml-4" color="success"
                  >Buy <v-icon small class="ml-2">mdi-phone-plus</v-icon>
                </v-btn>
              </template>
            </v-flex>
          </v-layout>
          <template>
            <v-data-table
              :headers="headersNumbers"
              :items="twilionumbers"
              dense
              :loading="loadingFree"
              class="elevation-1"
            >
              <template v-slot:[`item.sms`]="{ item }">
                <template v-if="item.capabilities.SMS">
                  <v-icon color="success">mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-icon color="error">mdi-close</v-icon>
                </template>
              </template>
              <template v-slot:[`item.voice`]="{ item }">
                <template v-if="item.capabilities.voice">
                  <v-icon color="success">mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-icon color="error">mdi-close</v-icon>
                </template>
              </template>
              <template v-slot:[`item.mms`]="{ item }">
                <template v-if="item.capabilities.MMS">
                  <v-icon color="success">mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-icon color="error">mdi-close</v-icon>
                </template>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="goBuyOne(item)"
                      v-on="on"
                      v-bind="attrs"
                      icon
                      color="success"
                      ><v-icon>mdi-cash-usd</v-icon></v-btn
                    >
                  </template>
                  <span>Buy Number</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-dialog
              v-model="itemBuy.dialog"
              persistent
              :overlay="false"
              max-width="300px"
              transition="dialog-transition"
            >
              <v-card>
                <v-card-title
                  class="
                    white--text
                    green
                    lighten-2
                    d-flex
                    justify-center
                    titlebuy
                  "
                >
                  Buy Number:
                </v-card-title>
                <v-card-subtitle
                  class="white--text green lighten-2 d-flex justify-center"
                >
                  {{ itemBuy.item != null ? itemBuy.item.phoneNumber : "" }}
                </v-card-subtitle>
                <v-card-text class="mt-2 text-center">
                  Are you sure?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="cancelBuy" text color="error">Cancel</v-btn>
                  <v-btn
                    :disabled="itemBuy.loading"
                    :loading="itemBuy.loading"
                    text
                    @click="confirmBuy"
                    color="success"
                    >Confirm</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="toShop(false)">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </div>
</template>
<script>
import rules from "@/components/account/rules";
import { mapActions, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import { notifySuccess } from "@/components/Notification";
import { apiBaseUrl } from "@/enviorment";
export default {
  data() {
    return {
      itemBuy: {
        dialog: false,
        loading: false,
        item: null,
        count: 0,
      },
      twilionumbers: [],
      loadingFree: false,
      headersNumbers: [
        {
          text: "Number",
          align: "start",
          sortable: false,
          value: "phoneNumber",
        },
        {
          text: "Locality",
          sortable: false,
          value: "locality",
        },
        {
          text: "SMS",
          sortable: false,
          value: "sms",
        },
        {
          text: "VOICE",
          sortable: false,
          value: "voice",
        },
        {
          text: "MMS",
          sortable: false,
          value: "mms",
        },
        { text: "", value: "actions", sortable: false },
      ],

      search: "",
      rules,
      buying: false,
      codeArea: "",
      phoneNumber: "",
      formValid: false,
      headersSettings: [
        {
          text: "Number",
          align: "start",
          sortable: false,
          value: "number",
        },

        { text: "Assigned to", value: "employee" },
        { text: "", value: "actions", sortable: false },
      ],
      freeDialog: false,
      dialogNumber: false,
      dialogDelete: false,
      assignDialog: false,
      itemSelect: {},
      req: {
        employeeUuid: "",
        numberUuid: "",
      },
    };
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["phoneNumbers", "loading"]),
    ...mapState("crmEmployeeModule", ["workers"]),
  },
  watch: {
    buying(val) {
      if (val) {
        this.getFreeNumbers();
      } else {
        this.twilionumbers = [];
        this.getNumbers();
      }
    },
  },
  methods: {
    ...mapActions("crmConfigurationsModule", [
      "actGetNumbers",
      "actAddNumbers",
      "actAssignNumber",
      "actDeleteNumber",
      "actFreeNumber",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    toShop(value) {
      this.buying = value;
    },
    toDelete(item) {
      this.itemSelect = item;
      this.dialogDelete = true;
    },
    toFree(item) {
      this.itemSelect = item;

      this.freeDialog = true;
    },
    toAsign(item) {
      this.itemSelect = item;
      this.assignDialog = true;
    },
    async assign() {
      this.req.numberUuid = this.itemSelect.uuid;
      await this.actAssignNumber(this.req);
      await this.getNumbers();
      this.close();
    },
    async deletenumber() {
      this.req.numberUuid = this.itemSelect.uuid;
      await this.actDeleteNumber(this.req.numberUuid);
      this.close();
    },
    async free() {
      this.req.numberUuid = this.itemSelect.uuid;
      await this.actFreeNumber(this.req.numberUuid);
      this.close();
    },

    async addNumber() {
      const number = "+1" + this.phoneNumber;
      await this.actAddNumbers(number);
      this.close();
    },
    async getNumbers() {
      await this.actGetNumbers();
    },

    repairNumber() {
      getAPI.put("/twiliocloud/repairTnumbers").then(() => {
        notifySuccess("All devices has been repair!!");
      });
    },

    close() {
      this.phoneNumber = "";
      this.dialogNumber = false;
      this.freeDialog = false;
      this.dialogDelete = false;
      this.assignDialog = false;
      this.itemSelect = {};
      this.req = { employeeUuid: "", numberUuid: "" };
    },

    //***Buy Twilio Numbers* */
    async getFreeNumbers() {
      try {
        let url = apiBaseUrl + "/twiliocloud/findFreeNumbers";
        if (
          this.codeArea.length >= 3 &&
          this.codeArea != "" &&
          this.codeArea != undefined
        ) {
          url = url + `?AreaCode=${this.codeArea}`;
        }
        this.loadingFree = true;
        const response = (await getAPI(url)).data;
        this.twilionumbers = response;
        this.loadingFree = false;
      } catch (error) {
        this.loadingFree = false;
      }
    },
    goBuyOne(item) {
      this.itemBuy = { dialog: true, loading: false, item: item, count: 1 };
    },
    confirmBuy() {
      try {
        this.itemBuy.loading = true;
        getAPI
          .put(apiBaseUrl + "/twiliocloud/buyNumber", {
            number: this.itemBuy.item.phoneNumber,
          })
          .then(() => {
            this.cancelBuy();
            this.getFreeNumbers();
          });
      } catch (error) {
        this.itemBuy.loading = false;
      }
    },
    cancelBuy() {
      this.itemBuy = { dialog: false, loading: false, item: null, count: 0 };
    },
  },
  async mounted() {
    await this.getNumbers();
    this.actGetEmployees();
  },
};
</script>
<style lang="scss" scoped>
.titlebuy {
  padding-top: -25px !important;
}
</style>
